import { Box, Button } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { mainColor } from "../../style/common";

const heading = "About";
const subHeading = "Creative Learning Opportunity For Kids";
const para1 =
  "With Kinderla, your child won't just learn, they’ll love learning. Kinderla also promotes emotional development and environmental awareness, building strong foundations for life. It's a great way to reduce screen time, allowing your child to enjoy meaningful activities while improving their fine motor skills, problem-solving abilities, and language proficiency";
const para2 =
  "Kinderla, more than just a magazine, it’s a gateway to a smarter, healthier, and happier childhood. Grab a copy today, and watch your child’s love for Learning.";
const About = () => {
  return (
    <div>
      <Box position={"relative"} mt={3} py={1} bgcolor={"#90ECFF"}>
        <Grid container>
          <Grid size={{ md: 5,sm:12 }}>
            <Box
              pt={{ md: 5 }}
              width={"100%"}
              display={"flex"}
              justifyContent={{md:"space-around",sm:'center'}}
            >
              <Box
                component="img"
                src="/images/about-img.png"
                alt="about-img"
                width={{md:"100%",xs:'100%',sm:400}}
              />
            </Box>
          </Grid>
          <Grid
            size={{ md: 7 }}
            container
            pr={{ md: "8vw" }}
            display={"flex"}
            alignItems={"top"}
            justifyContent={{xs:'center'}}
          >
            <Box width={"100%"} display={'flex'} justifyContent={{xs:'center'}}>
              <Typography
                color={mainColor}
                fontSize={40}
                fontFamily={"Skranji"}
                fontWeight={600}
              >
                {heading}
              </Typography>
            </Box>
            <Box width={"100%"} px={{xs:2}}>
              <Typography
                color={"#010103"}
                fontSize={30}
                fontFamily={"Readex Pro"}
                fontWeight={600}
              >
                {subHeading}
              </Typography>
            </Box>
            <Box width={"100%"} px={{xs:1}}>
              <Typography
                color={"#010103"}
                fontSize={20}
                fontFamily={"Readex Pro"}
                fontWeight={400}
              >
                <p>{para1}</p>
                <p>{para2}</p>
              </Typography>
            </Box>
            <Box width={"100%"} px={{xs:1}}>
              <Button
                size="medium"
                variant="contained"
                sx={{
                  background: mainColor,
                  px: 2,
                  color: "#ffff",
                  fontWeight: "bold",
                }}
              >
                Know More
              </Button>
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Box pl={10}>
                <img
                  src="/images/arrow.png"
                  alt="arrow_img"
                  height={70}
                  width={50}
                />
              </Box>
              <Box>
                <img
                  src="/images/stars.png"
                  alt="start_img"
                  height={100}
                  width={100}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default About;
