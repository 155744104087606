import React, { createContext, useContext, useState, ReactNode } from "react";

interface AppContextType {
  section: string | null;
  setSection: (section: string | null) => void;
  wonderla: boolean;
  setWonderla: (wonderla: boolean) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [section, setSection] = useState<string | null>(null);
  const [wonderla, setWonderla] = useState<boolean>(false);
  return (
    <AppContext.Provider
      value={{
        setSection,
        section,
        wonderla,
        setWonderla,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
};
