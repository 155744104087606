import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
const heading = "Customised School Magazine";
const subHeading = "Creative Learning Opportunity";
const desc =
  "Imagine their joy as they discover a whole new world beyond the screen, one that shapes their brain, enriches their imagination, and boosts their cognitive skills. Every page of Kinderla is thoughtfully created to encourage, excite, and engage.  ";

const cardData = [
  {
    src: "/images/school-magazine/blue-book.png",
    alt: "blue-book",
    heading: "Books",
    desc: "Lorem ipsum dolor sit amet consectetur. Auctor morbi sed ",
  },
  {
    src: "/images/school-magazine/pink-book.png",
    alt: "pink-book",
    heading: "Books",
    desc: "Lorem ipsum dolor sit amet consectetur. Auctor morbi sed ",
  },
  {
    src: "/images/school-magazine/yellow-book.png",
    alt: "yellow-book",
    heading: "Books",
    desc: "Lorem ipsum dolor sit amet consectetur. Auctor morbi sed ",
  },
];
const SchoolMagazine = () => {
  return (
    <div>
      <Box py={2} bgcolor={"#B7F2FE"}>
        <Grid container spacing={1}>
          <Grid
            size={{ md: 2, xs: 12,sm:2 }}
            display={"flex"}
            justifyContent={"space-around"}
          >
            <Box>
              <img
                src="/images/school-magazine/space.png"
                alt="space-image"
                width={200}
                height={180}
              />
            </Box>
          </Grid>
          <Grid size={{ md: 10, xs: 12,sm:10 }} container>
            <Grid size={12} display={"flex"} pl={{ md: 20, xs: 2 }}>
              <Typography
                color={mainColor}
                fontSize={40}
                fontFamily={"Skranji"}
                fontWeight={600}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid size={12} display={"flex"} pl={{ md: 20 }}>
              {" "}
              <Typography
                color={"#010103"}
                fontSize={30}
                fontFamily={"Readex Pro"}
                fontWeight={600}
                // display={'flex'}
                textAlign={"center"}
                alignItems={"center"}
                // lineHeight={0.2}
              >
                {subHeading}
                for Kids
              </Typography>
            </Grid>
            <Grid size={12} pr={{ md: 10 }} px={{ xs: 2 }}>
              {" "}
              <Typography
                color={"#010103"}
                fontSize={20}
                fontFamily={"Readex Pro"}
                fontWeight={400}
                display={"flex"}
                justifyContent={"center"}
                textAlign={"center"}
              >
                <p>{desc}</p>
              </Typography>
            </Grid>
            <Grid size={12} container>
              <Grid
                size={4}
                display={"flex"}
                justifyContent={"end"}
                px={{ md: 10 }}
              >
                <img
                  src="/images/school-magazine/bfly.png"
                  alt="bfly"
                  height={100}
                  width={100}
                />
              </Grid>
              <Grid
                size={{ md: 4, xs: 12, sm: 6 }}
                display={{ xs: "flex", sm: "flex" }}
                justifyContent={{ md: "center" ,sm:'center'}}
              >
                <Box
                  border={"1px solid #ED145B"}
                  display={"flex"}
                  borderRadius={"8px"}
                  width={{ md: "80%", xs: "100%" }}
                  height={"60px"}
                >
                  <Box
                    width={"10%"}
                    bgcolor={"#ED145B"}
                    p={2}
                    borderRadius={"8px"}
                  >
                    <CallOutlinedIcon sx={{ color: "#FFFF" }} />
                  </Box>
                  <Box width={"80%"}>
                    <Typography
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      py={0.3}
                      fontSize={18}
                      fontWeight={400}
                    >
                      Call For Any Query
                    </Typography>
                    <Typography
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      fontSize={18}
                      fontWeight={600}
                    >
                      1234567890{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                size={{ md: 4, xs: 12 }}
                display={"flex"}
                justifyContent={{ xs: "center" }}
              >
                <img
                  src="/images/school-magazine/table.png"
                  alt=""
                  height={200}
                  width={220}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12} container rowSpacing={{xs:2}} display={{sm:'flex'}} justifyContent={{ sm:'center'}} px={{ xs:2}}>
            {cardData?.map((item: any, index) => (
              <Grid size={{ md: 4, xs: 12,sm:6 }} key={index} px={{ md: 7 }}>
                <Box bgcolor={"#ffff"} height={"300px"} borderRadius={"40px"}>
                  <Box
                    width={"100%"}
                    height={"150px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={item?.src}
                      alt={item?.alt}
                      width={100}
                      height={100}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Typography
                      color={mainColor}
                      fontSize={18}
                      fontFamily={"Skranji"}
                      fontWeight={500}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      {item?.heading}
                    </Typography>
                  </Box>
                  <Box width={"100%"} mt={2}>
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      display={"flex"}
                      justifyContent={"center"}
                      textAlign={"center"}
                      px={5}
                    >
                      {item?.desc}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SchoolMagazine;
