import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";
const heading = "Testimonials";
const reviewHeading = "Nurturing Young Minds";
const review =
  "Reducing kids’ screen time, espe cially on mobile phones in today’s  digital age, can be challenging. Cre ative and engaging activities help to  divert their attention from screens.  This kind of magazine surely helps  children for their physical, mental,  and emotional wellbeing. Our brain is a supercomputer. The  right cerebral hemisphere receives  sensory input and directs move ments on the left side of the body,  while the left hemisphere governs  corresponding functions for the  right side. The left side of the ce rebrum is associated with speech  and processing language, while the  right is associated with nonverbal  memory and spatial awareness. So, mental stability and sharpness  are pivotal factors in enriching their  lives in meaningful ways. Reducing  kids’ screen time doesn’t have to  be a battle. By offering a variety of  engaging and fun activities, parents  can help their children to develop  healthy habits and skills that will  benefit them for years to come.";
const reviewName = "Dr Bejoy Nambiar  ";
const desig = "BHMS,PGDCH, PGDMLD,PGDAC";
const Testimonials = () => {
  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url('/images/testimonials/bg2.png')`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: { md: "100%", xs: "100%" }, // Set your desired height
          // py: 5,
        }}
      >
        <Grid container>
        <Grid
            size={{md:2,xs:12,sm:3}}
            display={{ md: "flex", xs: "grid" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
         
            <img
              src="/images/testimonials/rainbow.png"
              alt="heading_img"
              width={250}
              height={220}
            />
          </Grid>
          <Grid
            size={{md:10,xs:12,sm:9}}
            display={{ md: "flex", xs: "grid",sm:'flex' }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {" "}
            <Typography
              color={mainColor}
              fontSize={40}
              fontFamily={"Skranji"}
              fontWeight={600}
            >
              {heading}
            </Typography>
            <img
              src="/images/testimonials/headingStar.png"
              alt="heading_img"
              width={250}
              height={220}
            />
          </Grid>

          <Grid size={12} container >
            <Grid size={{ md: 10, xs: 12,sm:9 }}>
              <Box>
              <Typography
                color={"#000"}
                fontSize={25}
                fontFamily={"Skranji"}
                fontWeight={500}
               display={'flex'}
              //  justifyContent={'center'}
                alignItems={"center"}
                px={3}
                pb={2}
              >
                {reviewHeading}
              </Typography>
              <Typography
                color={"#000"}
                fontSize={16}
                fontFamily={"Readex Pro"}
                fontWeight={400}
                // display={"flex"}
                // justifyContent={"center"}
                // textAlign={"center"}
                px={3}
              >
                {review}
              </Typography>
              <Typography
                color={"#000"}
                fontSize={16}
                fontFamily={"Readex Pro"}
                fontWeight={600}
              
                px={3}
                pt={2}
              >
                {reviewName}
              </Typography>
              <Typography
                color={"#000"}
                fontSize={16}
                fontFamily={"Readex Pro"}
                fontWeight={400}
                // display={"flex"}
                // justifyContent={"center"}
                // textAlign={"center"}
                px={3}
                // py={1}
              >
                {desig}
              </Typography>
              </Box>
            </Grid>
            <Grid size={{ md: 2, xs: 12,sm:3 }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Avatar
                src={"/images/testimonials/reviewer.png"}
                alt={"data"}
                sx={{
                  width: 120,
                  height: 120,
                  border: "7px solid #FF730E", // Optional: Add border
                  boxShadow: 3,
                }}
              />
            </Grid>
          </Grid>
         <Grid size={12} display={'flex'} justifyContent={'space-around'}>
          <img src="/images/testimonials/kid.png" alt="kid" width={200} height={200} />
          {/* <img src="/images/testimonials/rocket.png" alt="kid" width={250} height={300} /> */}

         </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Testimonials;
