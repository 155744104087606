import { Box } from "@mui/material";
import React from "react";

const Layer2 = () => {
  return (
    <div style={{ position: "relative", height: "100px", width: "100%" }}>
      <Box
        sx={{
          backgroundImage: "url('/images/division_dark.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 10,
          left: 0,
          height: "100px",
          width: "100%",
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          backgroundImage: "url('/images/division_light.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 30,
          left: 0,
          height: "100px",
          width: "100%",
          zIndex: 2,
        }}
      />
    </div>
  );
};

export default Layer2;
