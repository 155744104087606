import { Box, Button, IconButton, Popper } from "@mui/material";
import React from "react";
import Layer2 from "../../../components/devider/Devider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { mainColor } from "../../../style/common";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppContext } from "../../../context/ScrollContext";

const heading = "Discover, Dream, and Grow with Books Kids Love";
const LandingPage = () => {
  const { setWonderla, wonderla } = useAppContext();
  return (
    <div style={{ position: "relative", height: "95vh", width: "100%" }}>
      <Box
        sx={{
          backgroundImage: "url('/images/kid_image.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "95vh",
          width: "100%",
        }}
      >
        <Box width={"100%"}>
          <Box>
            <Grid container px={{ md: "7vw", xs: 2 }}>
              <Grid size={12} display={"flex"} justifyContent={"end"}>
                <Box p={1} display={"flex"} alignItems={"top"}>
                  {wonderla ? (
                    <>
                      <div className="" onClick={() => setWonderla(false)}>
                        <CancelIcon fontSize="medium" sx={{ color: "white" }} />
                      </div>
                      <img
                        src="/images/wonderla.png"
                        alt="wonderla"
                        width={330}
                        height={200}
                      />
                    </>
                  ) : (
                    <>
                      <Box height={200} width={330}></Box>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid size={{ md: 12 }} mt={{ md: "1vh", xs: "1vh" }}>
                <Typography
                  color="#FFFFFF"
                  fontWeight={{ md: 800, xs: 700 }}
                  fontSize={{ md: 70, xs: 50 }}
                  lineHeight={1}
                  fontFamily={"Skranji"}
                >
                  {heading}
                </Typography>
              </Grid>
              <Grid size={{ md: 12 }} mt={1}>
                <Button
                  size="medium"
                  variant="contained"
                  sx={{
                    background: mainColor,
                    px: 2,
                    color: "#ffff",
                    fontWeight: "bold",
                  }}
                >
                  Know More
                </Button>{" "}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Layer2 />
      </Box>
    </div>
  );
};

export default LandingPage;
