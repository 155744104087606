import React, { useEffect, useRef } from "react";
import LandingPage from "./Components/LandingPage";
import About from "../about/About";
import Magazine from "../magazine/Magazine";
import SchoolMagazine from "../school-magazine/SchoolMagazine";
import Samplemagazine from "../sample-magazine/Samplemagazine";
import Phones from "../Phones/Phones";
import Gallery from "../gallery/Gallery";
import Testimonials from "../testimonials/Testimonials";
import Blog from "../Blog/Blog";
import { useAppContext } from "../../context/ScrollContext";

const Home = () => {
  const { section } = useAppContext();
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const magazineRef = useRef(null);
  const cMagazineRef = useRef(null);
  const blogRef = useRef(null);
  const galleryRef = useRef(null);
  const testimonialsRef = useRef(null);
  const whykinderlaRef = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (section === "home") {
      scrollToSection(homeRef);
    } else if (section === "about") {
      scrollToSection(aboutRef);
    } else if (section === "magazine") {
      scrollToSection(magazineRef);
    } else if (section === "blogs") {
      scrollToSection(blogRef);
    } else if (section === "gallery") {
      scrollToSection(galleryRef);
    } else if (section === "custom-magazine") {
      scrollToSection(cMagazineRef);
    } else if (section === "testimonials") {
      scrollToSection(testimonialsRef);
    } else if (section === "why-kinderla") {
      scrollToSection(whykinderlaRef);
    }
  }, [section]);

  return (
    <div>
      <section ref={homeRef}>
        <LandingPage />
      </section>
      <section ref={aboutRef}>
        <About />
      </section>
      <section ref={magazineRef}>
        <Magazine />
      </section>
      <section ref={cMagazineRef}>
        <SchoolMagazine />
        <Samplemagazine />
      </section>
      <section ref={whykinderlaRef}>
        <Phones />
      </section>
      <section ref={galleryRef}>
        <Gallery />
      </section>
      <section ref={testimonialsRef}>
        <Testimonials />
      </section>
      <section ref={blogRef}>
        <Blog />
      </section>
    </div>
  );
};

export default Home;
