import React from "react";
import Grid from "@mui/material/Grid2";
import { Box, Button, Typography } from "@mui/material";
import { bgColor } from "../../style/common";
import { useAppContext } from "../../context/ScrollContext";

const Footer = () => {
  const { setSection } = useAppContext();
  return (
    <div>
      <Box
        sx={{
          background: bgColor,
          height: "1px",
          width: "100%",
        }}
      />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 3 }}
        p={{ md: 3 }}
      >
        <Grid
          size={{ md: 3, xs: 12 }}
          py={2}
          display={"flex"}
          justifyContent={"center"}
        >
          <img src="/logo/logo-footer.png" alt="logo" height={60} width={200} />
        </Grid>
        <Grid
          size={{ md: 3, xs: 6, sm: 4 }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Box>
            <Typography fontWeight={600} fontSize={"18px"}>
              {" "}
              <span
                style={{
                  background: bgColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Menu
              </span>
            </Typography>
            {[
              { label: "Home", section: "home" },
              { label: "About", section: "about" },
              { label: "Gallery", section: "gallery" },
              { label: "Blog", section: "blog" },
            ]?.map((item: any) => (
              <div className="" onClick={() => setSection(item?.section)}>
                <Typography
                  key={item}
                  fontSize={"15px"}
                  fontWeight={400}
                  py={0.5}
                >
                  {item?.label}
                </Typography>
              </div>
            ))}
          </Box>
        </Grid>
        <Grid
          size={{ md: 3, xs: 6, sm: 4 }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Box>
            <Typography fontWeight={600} fontSize={"18px"}>
              {" "}
              <span
                style={{
                  background: bgColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Info
              </span>
            </Typography>
            {["About Us", "Magazines"]?.map((item: string) => (
              <Typography
                key={item}
                fontSize={"15px"}
                fontWeight={400}
                py={0.5}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid
          size={{ md: 3, xs: 12, sm: 4 }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Box p={2}>
            <Typography fontWeight={600} fontSize={"18px"}>
              {" "}
              <span
                style={{
                  background: bgColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Contact Us
              </span>
            </Typography>
            {["1234567", "music@gmail.com"]?.map((item: string) => (
              <Typography
                key={item}
                fontSize={"15px"}
                fontWeight={400}
                py={0.5}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
