import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";

const heading = "Blog";

const listData = [
  {
    src: "/images/Blog/img6.png",
    desc: "Morning routine to boost your mood",
    alt: "img6",
  },
  {
    src: "/images/Blog/img1.png",
    desc: "Morning routine to boost your mood",
    alt: "img1",
  },
  {
    src: "/images/Blog/img2.png",
    desc: "Morning routine to boost your mood",
    alt: "img2",
  },
  {
    src: "/images/Blog/img3.png",
    desc: "Morning routine to boost your mood",
    alt: "img3",
  },
  {
    src: "/images/Blog/img4.png",
    desc: "Morning routine to boost your mood",
    alt: "img4",
  },
  {
    src: "/images/Blog/img5.png",
    desc: "Morning routine to boost your mood",
    alt: "img5",
  },
];
const Blog = () => {
  return (
    <div>
      <Box bgcolor={"#86D8E3"} py={4}>
        <Grid container>
          <Grid size={{md:4,xs:12,sm:4}} >
            <img
              src="/images/Blog/star.png"
              alt="star"
              width={200}
              height={200}
            />
          </Grid>
          <Grid size={{md:4,xs:12,sm:4}} display={"flex"} justifyContent={{xs:'center'}} alignItems={"center"}>
            {" "}
            <Typography
              color={mainColor}
              fontSize={40}
              fontFamily={"Skranji"}
              fontWeight={600}
              display={"flex"}
              alignItems={"center"}
            >
              {heading}
              <img
                src="/images/Blog/draw.png"
                alt="star"
                width={50}
                height={120}
              />
            </Typography>
          </Grid>
          <Grid size={{md:4,xs:12,sm:4}} display={{xs:'flex'}} justifyContent={{xs:'end'}}>
            <img
              src="/images/Blog/sun.png"
              alt="star"
              width={150}
              height={120}
            />
          </Grid>

          <Grid size={12} container rowSpacing={2}>
            {listData?.map((item: any, index: any) => (
              <Grid size={{md:4,xs:12,sm:6}}>
                <Box>
                    <Box display={'flex'} justifyContent={'center'}>
                    <img
                    src={item?.src}
                    alt={item?.alt}
                    width={200}
                    height={200}
                  />
                    </Box>
                
                  <Typography
                    color={"#000"}
                    fontSize={16}
                    fontFamily={"Readex Pro"}
                    fontWeight={400}
                    // display={"flex"}
                    // justifyContent={"center"}
                    textAlign={"center"}
                    // px={{ md: 20 }}
                  >
                    {item?.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Blog;
