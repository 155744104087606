import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
 interface Prop{
src :string
 }
const BackToSchoolCard :React.FC<Prop>= ({src}) => {
  return (
    
    <Card
      sx={{
        maxWidth: '100%',
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: 3,
        backgroundColor: '#0193E5',
        color: '#fff',
        margin: 'auto',
      }}
    >
      {/* Image Section */}
      <Box pt={4} px={4}>
      <CardMedia
        component="img"
        height="400"
        image={src} // Replace with the actual image path
        alt="Back to School"
      />
      </Box>
      {/* Content Section */}
      <Box
        sx={{
          backgroundColor: '#fff',
          color: '#000',
          padding: '16px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        </Typography>
      </Box>
    </Card>
  );
};

export default BackToSchoolCard;
