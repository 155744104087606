import { Box } from '@mui/material';
import React from 'react';

const Layer1 = () => {
    return (
        <div style={{ position: "relative", height: "70px", width: "100%" }}>
        <Box
          sx={{
            backgroundImage: "url('/images/layer1.png')",
            backgroundSize: "cover",
            // backgroundPosition: "center",
            position: "absolute",
            top: 10,
            left: 0,
            height: "70px",
            width: "100%",
            zIndex: 1,
          }}
        />
  
      
      </div>
    );
}

export default Layer1;
