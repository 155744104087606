import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";
const mainHeading = "Why Kinderla";
const heading = "Phones can be villains!";
const subHeading = "Do you know?";
const desc =
  "Studies say that giving a smartphone or tablet to a kid early in life can even develop mental health problems";

const dataList = [
  {
    src: "/images/phones/img1.png",
    alt: "img1",
  },
  {
    src: "/images/phones/img2.png",
    alt: "img2",
  },
  {
    src: "/images/phones/img3.png",
    alt: "img3",
  },
  {
    src: "/images/phones/img4.png",
    alt: "img4",
  },
  {
    src: "/images/phones/img5.png",
    alt: "img5",
  },
];
const Phones = () => {
  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url('/images/phones/phones.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: { md: "110vh", xs: "100%" }, // Set your desired height
          py: 5,
        }}
      >
        <Grid container>
          <Grid size={{ md: 12 }} display={'flex'} justifyContent={'center'}>
            {" "}
            <Typography
              color={mainColor}
              fontSize={40}
              fontFamily={"Skranji"}
              fontWeight={600}
            >
              {mainHeading}
            </Typography>
          </Grid>
          <Grid size={{ md: 6, xs: 12 }}>
            <Box px={5} pt={{ md: 12 }} pl={{ md: 20 }}>
              <Typography
                color={'#fedb73'}
                fontSize={40}
                fontFamily={"Skranji"}
                fontWeight={600}
              >
                {heading}
              </Typography>
              <Typography
                color={"#010103"}
                fontSize={30}
                fontFamily={"Readex Pro"}
                fontWeight={600}
                // display={'flex'}
                // textAlign={"center"}
                alignItems={"center"}
                lineHeight={0.2}
                py={2}
              >
                {subHeading}
              </Typography>

              <Typography
                color={"#fff"}
                fontSize={20}
                fontFamily={"Readex Pro"}
                fontWeight={400}
                // display={"flex"}
                // justifyContent={"center"}
                textAlign={"center"}
                // px={{ md: 20 }}
              >
                <p>{desc}</p>
              </Typography>
            </Box>
          </Grid>
          <Grid
            size={{ md: 6, xs: 12 }}
            py={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box
              component="img"
              src="/images/phones/child-group.png"
              alt="child-group"
              sx={{
                height: { md: 350, xs: 250, sm: 300 },
                width: { md: 500, xs: "100%", sm: 500 },
              }}
            />{" "}
          </Grid>
          <Grid
            size={12}
            container
            mt={4}
            py={4}
            display={"flex"}
            justifyContent={"center"}
          >
            {dataList?.map((item) => (
              <Grid
                size={{ md: 2, xs: 12, sm: 6 }}
                px={{ md: 4, xs: 2 }}
                display={{ xs: "flex" }}
                justifyContent={{ xs: "center" }}
              >
                <img src={item?.src} alt={item?.alt} height={250} width={200} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Phones;
