import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";
import BackToSchoolCard from "./BackToSchoolCard";
import GrassLayer from "../../components/devider/GrassLayer";

const heading = "Sample Magazine";
const desc =
  "Kinderla isn’t just pages; it's a journey that encourages cognitive growth, critical thinking, and creativity. Each section is designed to ignite curiosity in subjects like Maths, English, Science, Art, Logic, and Social Studies. ";
const cardDesc =
  "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea";

const cardData = [
  {
    src: "/images/sample-magazine/book1.png",
  },
  {
    src: "/images/sample-magazine/book2.png",
  },
  {
    src: "/images/sample-magazine/book3.png",
  },
];
const Samplemagazine = () => {
  return (
    <div>
      <Box
        sx={{
          backgroundImage: "url('/images/sample-magazine/bg.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        py={2}
        pb={17}
      >
        <Grid container>
          <Grid size={{ md: 3, xs: 12 }}>
            <Box
              component="img"
              src="/images/sample-magazine/space.png"
              alt="space-img"
              sx={{
                width:{md: "100%",sm:400,xs:300},
              }}
            />{" "}
          </Grid>
          <Grid size={{ md: 6, xs: 12 }} container>
            <Grid
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {" "}
              <Typography
                color={mainColor}
                fontSize={40}
                fontFamily={"Skranji"}
                fontWeight={600}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid size={12}>
              {" "}
              <Typography
                color={"#010103"}
                fontSize={20}
                fontFamily={"Readex Pro"}
                fontWeight={400}
                display={"flex"}
                justifyContent={"center"}
                textAlign={"center"}
              >
                {desc}
              </Typography>
            </Grid>
          </Grid>
          <Grid size={{ md: 3, xs: 12 }} display={'flex'} justifyContent={{xs:'end'}}>
           
              <Box
              component="img"
              src="/images/sample-magazine/rocket.png"
              alt="space-img"
              sx={{
                width:{md: "100%",sm:400,xs:200},
              }}
            />
          </Grid>
          <Grid size={12} container px={{ md: 5,sm:2,xs:1 }} rowSpacing={2} columnSpacing={2} display={'flex'} justifyContent={'center'}>
            {cardData?.map((item: any) => {
              return (
                <Grid size={{ md: 4, xs: 12,sm:6 }}>
                  <Box px={{md:5}}>
                    <BackToSchoolCard src={item?.src} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Samplemagazine;
