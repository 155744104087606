import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";
import Layer1 from "../../components/devider/Layer1";

const heading = "Magazine";
const subHeading = "We Educate Knowledge & Essential Skills";
const desc =
  "Are you looking for a way to spark creativity in your child and keep them away from screens? Then choose Kinderla – an interactive magazine crafted to inspire young minds and foster early literacy skills in a fun, engaging way.  It acts like a friend, one that helps your child dive into stories, solve puzzles, make choices, and explore new ideas.";
const magazines:any[] = [
  {
    src:'/images/magazine/book1.png',
    alt: 'book1'
  },
  {
    src:'/images/magazine/book2.png',
    alt: 'book2'
  },
  {
    src:'/images/magazine/book3.png',
    alt: 'book3'
  },
  {
    src:'/images/magazine/book4.png',
    alt: 'book4'
  },
]
const Magazine = () => {
  return (
    <div style={{position:'relative'}}>
      <Box>
        <Grid container py={1}>
          <Grid size={{md:11,xs:9}} container>
            <Grid size={12}>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src="/images/magazine/react.png"
                  alt="react_img"
                  width={150}
                  height={150}
                  // style={{ paddingRight: "10vw" }}
                />
                <Typography
                  color={mainColor}
                  fontSize={40}
                  fontFamily={"Skranji"}
                  fontWeight={600}
                >
                  {heading}
                </Typography>
              </Box>

              <Grid size={12} display={"flex"} justifyContent={"center"} px={{xs:2}}>
                <Typography
                  color={"#010103"}
                  fontSize={30}
                  fontFamily={"Readex Pro"}
                  fontWeight={600}
                >
                  {subHeading}
                </Typography>
              </Grid>
              <Grid size={12} px={{xs:1}}>
                <Typography
                  color={"#010103"}
                  fontSize={20}
                  fontFamily={"Readex Pro"}
                  fontWeight={400}
                  display={"flex"}
                  justifyContent={"center"}
                  textAlign={"center"}
                >
                  <p>{desc}</p>
                </Typography>
              </Grid>
              <Grid container size={12}>
                <Grid size={4} display={'flex'} justifyContent={'center'}>
                  <img
                    src="/images/magazine/book.png"
                    alt="book_image"
                    width={250}
                    height={250}
                  />
                </Grid>

                <Grid size={4} display={'flex'} justifyContent={'center'} alignItems={'end'}>
                  <img
                    src="/images/magazine/butterfly.png"
                    alt="butterfly_img"
                    width={150}
                    height={150}
                  />
                </Grid>
              </Grid>
              <Grid size={12} container>
{magazines?.map((item:any,index:number)=>(
  <Grid size={{md:3,sm:6,xs:12}} p={2} key={index}>
  <img src={item?.src} alt={item?.alt} height={'90%'} width={'100%'} />

</Grid>
))}
              

              </Grid>
            </Grid>
          </Grid>
          <Grid size={{md:1,xs:3}}>
            <img
              src="/images/magazine/hand-group.png"
              alt="hand-group"
              width={"100%"}
              height={"100%"}
            />
          </Grid>
        </Grid>
      </Box>{" "}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Layer1/>
      </Box>
    </div>
  );
};

export default Magazine;
