import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { mainColor } from "../../style/common";

const heading = "Gallery";
const subHeading = "Creative Learning Opportunity For Kids";

const Gallery = () => {
  return (
    <div>
      <Box py={{xs:5}}>
        <Grid container spacing={1}>
          <Grid
            size={{ md: 3, xs: 12 }}
            display={"flex"}
            justifyContent={"space-around"}
          >
            <img
              src="/images/gallery/teddy.png"
              alt="teddy"
              width={230}
              height={200}
            />
          </Grid>
          <Grid size={{ md: 6, xs: 12,sm:8 }} container>
            <Grid
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {" "}
              <Typography
                color={mainColor}
                fontSize={40}
                fontFamily={"Skranji"}
                fontWeight={600}
                display={"flex"}
                justifyContent={"center"}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography
                color={"#010103"}
                fontSize={30}
                fontFamily={"Readex Pro"}
                fontWeight={600}
                // display={'flex'}
                textAlign={"center"}
              >
                {subHeading}
              </Typography>
            </Grid>
          </Grid>
          <Grid size={{ md: 3, xs: 12,sm:4 }} display={'flex'}>
            {" "}
            <img
              src="/images/gallery/alien.png"
              alt="alien"
              width={200}
              height={230}
            />
          </Grid>
          {/* ============================image grid================== */}
          <Grid size={12} container spacing={2} px={{md:10,xs:1}}>
            <Grid size={{ md: 5, xs: 12,sm:5 }}>
              <img
                src="/images/gallery/img1.png"
                alt="img"
                width={"100%"}
                height={"450"}
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "15px",
                }}
              />
            </Grid>
            <Grid size={{ md: 4, xs: 12,sm:6 }} container spacing={2}>
              <Grid size={{ md: 6, xs: 12 ,sm:6}}>
                <img
                  src="/images/gallery/img2.png"
                  alt="img"
                  width={"100%"}
                  height={"220px"}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
              <Grid size={{ md: 6, xs: 12,sm:6 }}>
                {" "}
                <img
                  src="/images/gallery/img3.png"
                  alt="img"
                  width={"100%"}
                  height={"220px"}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
              <Grid size={12}>
                {" "}
                <img
                  src="/images/gallery/img4.png"
                  alt="img"
                  width={"100%"}
                  height={"200px"}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
            </Grid>
            <Grid size={{ md: 3, xs: 12 }} container>
              <Grid size={{md:12,sm:6,xs:12}}>
                <img
                  src="/images/gallery/img5.png"
                  alt="img"
                  width={"100%"}
                  height={"200px"}
                  style={{ objectFit: "cover", borderRadius: "15px" }}
                />
              </Grid>
              <Grid size={{md:12,sm:6,xs:12}}>
                <img
                  src="/images/gallery/img1.png"
                  alt="img"
                  width={"100%"}
                  height={"200px"}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid size={12}>
            {" "}
            <img src="/images/gallery/footer.png" alt="footer" width={"100%"} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Gallery;
