import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { bgColor } from "../../style/common";
import { useAppContext } from "../../context/ScrollContext";

const menuItems = [
  { label: "Home", path: "/", section: "home" },
  { label: "About", path: "/about", section: "about" },
  {
    label: "Customized Magazines",
    path: "/custom-magazine",
    section: "custom-magazine",
  },
  {
    label: "Why Kinderla",
    path: "/custom-magazine",
    section: "whykinderla",
  },
  { label: "Magazines", path: "/magazine", section: "magazine" },
  { label: "Testimonials", path: "/blogs", section: "testimonials" },
  { label: "Blogs", path: "/blogs", section: "blogs" },

  { label: "Gallery", path: "/gallery", section: "gallery" },
];

const Header = () => {
  const navigate = useNavigate();
  const { setSection, setWonderla } = useAppContext();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      position={{ md: "fixed" }}
    >
      <List>
        <ListItem sx={{ display: "flex", justifyContent: "center" }}>
          <img src="/logo/logo.png" alt="logo" height={50} width={150} />{" "}
        </ListItem>
        {menuItems.map((item: any, index) => (
          <ListItem key={index} onClick={() => setSection(item?.section)}>
            <Typography fontWeight={400} p={0.3} fontSize={"16px"}>
              {item?.label}
            </Typography>
          </ListItem>
        ))}
        <ListItem >
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{ background: bgColor, px: 2 }}
            onClick={() => setWonderla(true)}
          >
            Free Wonderla Tickets
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className="app-bar">
      <Box sx={{ flexGrow: 1, background: "white" }}>
        <AppBar position="static">
          <Toolbar variant="dense" sx={{ background: "#fff", height: "70px" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
              alignItems={"center"}
            >
              <Box>
                <img
                  src="/logo/logo-footer.png"
                  alt="logo"
                  height={40}
                  width={150}
                />
              </Box>
              <Box display={{ xs: "none", md: "flex" }}>
                <Box onClick={() => setWonderla(true)} px={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{ background: bgColor, px: 2 }}
                  >
                    Free Wonderla Tickets
                  </Button>
                </Box>
                {menuItems.map((item: any, index) => (
                  <Box
                    key={index}
                    onClick={() => setSection(item?.section)}
                    px={2}
                    display={"flex"}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography fontSize={18} fontWeight={550} color="#1E1E1E">
                      {item?.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box display={{ xs: "flex", md: "none" }}>
                <IconButton
                  sx={{ color: "#000" }}
                  edge="start"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerList}
        </Drawer>
      </Box>
    </div>
  );
};
export default Header;
